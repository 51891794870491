import { gql } from '@apollo/client';

export const GET_BOOK = gql`
	query GetAuthorBook($book_id: String!) {
		book: authorBook(book: { book_id: $book_id }) {
			book_id
			title
			description
			cover_link
			genres {
				genre_id
				title
				link
				icon_link
			}
			tags {
				title
				tag_id
			}
			creator_id {
				user_id
				first_name
				last_name
				photo_link
				pen_name
			}
			status
			updated_at
			language {
				language_id
				keyword
				language
			}
			moderated_by {
				user_id
				first_name
				last_name
			}
			created_at
			full_screen_link
			read_count
			likes
			published
			deleted
			firstChapterId
			chapters {
				chapter_id
				status
				title
				chapter_order
				updated_at
				cdn_link
				bucket_link
				tokensAmount
			}
			book_contract {
				contract_status
				external_contract_description
				contract_link
				reject_reason
				permanent_reject
				isVerified
				type
			}
		}
	}
`;

export const GET_FULL_BOOK = gql`
	query GetAuthorBook($book_id: String!) {
		book: books(
			where: [
				{books: {book_id: $book_id}},
			]
			cache: 0
			relations: [
				"creator_id",
				"genres",
				"tags",
				"language",
				"chapters",
				"recommendations"
			]
		) {
			book_id
			title
			description
			cover_link
			updated_at
			read_count
			likes
			status
			deleted
			isExclusive
			genres {
				genre_id
				title
			}
			tags {
				title
				tag_id
			}
			language {
				language_id
				keyword
				language
			}
			recommendations {
				book_id
			}
			creator_id {
				user_id
				first_name
				last_name
				pen_name
				status
				published_books_count
				email
				photo_link
			}
			book_contract {
				contract_status
				external_contract_description
				external_contract_link
				contract_link
				reject_reason
				permanent_reject
				isVerified
				type
			}
		}
	}
`;

export const GET_FULL_LOCALIZED_BOOK = gql`
	query GetAuthorBook($book_id: String!) {
		localeBook: books(
			where: [
				{books: {book_id: $book_id}},
			]
			cache: 0
			relations: [
				"creator_id",
				"genres",
				"tags",
			]
		) {
			book_id
			title
			description
			genres {
				genre_id
				title
			}
			tags {
				title
				tag_id
			}
			creator_id {
				user_id
				first_name
				last_name
				pen_name
			}
		}
	}
`;

export const GET_RECOMMENDATION_BOOKS_DATA = gql`
	query GetAuthorBook($recommendations: [JSONObject!]!) {
		books: books(
			where: $recommendations
			cache: 0
			relations: [
				"creator_id",
				"language",
			]
		) {
			book_id
			title
			cover_link
			status
			deleted
			language {
				language_id
				keyword
				language
			}
			creator_id {
				user_id
				first_name
				last_name
				pen_name
			}
		}
	}
`;

export const CREATE_BOOK = gql`
	mutation CreateBook($book: CreateBookDto!) {
		book: createBook(book: $book) {
			book_id
			title
			description
			cover_link
			genres {
				genre_id
				title
				link
				icon_link
			}
			tags {
				title
				tag_id
			}
			creator_id {
				user_id
				first_name
				last_name
				photo_link
			}
			status
			updated_at
			language {
				language_id
				keyword
				language
			}
			created_at
			full_screen_link
			read_count
			likes
		}
	}
`;

export const UPDATE_BOOK = gql`
	mutation UpdateBook($book: UpdateBookDto!) {
		book: updateBook(book: $book) {
			book_id
			title
			description
			status
			updated_at
			read_count
			likes
		}
	}
`;

export const DISCARD_BOOK_CHANGES = gql`
	mutation DiscardBookChanges($book_id: String!) {
		book: discardBookChanges(book: { book_id: $book_id }) {
			book_id
			title
			description
			cover_link
			genres {
				genre_id
				title
				link
				icon_link
			}
			tags {
				title
				tag_id
			}
			status
			updated_at
			language {
				language_id
				keyword
				language
			}
			created_at
			full_screen_link
			read_count
			likes
		}
	}
`;

export const UPDATE_BOOK_ADMIN = gql`
	mutation UpdateBookByAdmin($book: UpdateBookAdminDto!) {
		book: updateBookAdmin(book: $book) {
			book_id
			title
			description
			cover_link
			genres {
				genre_id
				title
				link
				icon_link
			}
			tags {
				title
				tag_id
			}
			status
			updated_at
			language {
				language_id
				keyword
				language
			}
			created_at
			full_screen_link
			read_count
			likes
		}
	}
`;

export const UPDATE_BOOK_STATUS = gql`
	mutation UpdateBookStatus($book: UpdateBookStatus!) {
		story: updateBookStatus(book: $book) {
			book_id
			status
		}
	}
`;

export const DELETE_BOOK = gql`
	mutation DeleteBook($book_id: String!, $reason: String) {
		book: deleteBook(book: { book_id: $book_id, reason: $reason }) {
			book_id
			status
			deleted
		}
	}
`;

export const RESTORE_DRAFT_BOOK = gql`
	mutation RestoreDraftBook($book_id: String!) {
		book: restoreDraftBook(book: { book_id: $book_id }) {
			book_id
			status
			deleted
		}
	}
`;

export const CAN_DELETE_BOOK = gql`
	query CanDeleteBook($book_id: String!) {
		canDeleteBook(book: { book_id: $book_id })
	}
`;

export const UPDATE_BOOK_RECOMMENDATIONS = gql`
	mutation updateBookAdmin(
		$book_id: String!
		$recommendations: [BookIdentityDto!]
	) {
		story: updateBookAdmin(
			book: { book_id: $book_id, recommendations: $recommendations }
		) {
			book_id
		}
	}
`;

export const UPDATE_BOOK_CREATOR = gql`
	mutation updateBookAdmin(
		$book_id: String!
		$creator_id: UserIdentityDto!
	) {
		story: updateBookAdmin(
			book: { book_id: $book_id, creator_id: $creator_id }
		) {
			book_id
		}
	}
`;

export const CAN_PUBLISH_BOOK = gql`
	query CanPublishBook($book_id: String!) {
		canPublish(
			request: { book: { book_id: $book_id } }
		) {
			result
			warnings {
				id
				reason
			}
			errors {
				reason
			}
		}
	}
`;

export const NEW_UPDATE_RECOMMENDATIONS = gql`
	mutation UpdateRecommendations(
		$book_id: String!
		$recommendations: [CreateRecommendation!]!
	) {
		result: updateRecommendations(
			book: { book_id: $book_id },
			recommendations: $recommendations,
		)
	}
`;
